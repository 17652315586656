<template>
  <div id="container">
    <header-navbar class="sticky" bgTheme="bg-shallow-blue"></header-navbar>

    <div class="image-box">
      <img class="bg-1" :src="pageImage['bg-1']" alt="" />
      <div class="click-box">
        <grid-box-item
          :path="item"
          v-for="item in routes"
          :key="item"
        ></grid-box-item>
      </div>
    </div>

    <!-- 底部 -->
    <floor-bg class="floor-bg" bgTheme="floor-bg-white"></floor-bg>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue'
const pageImage = computed(() => {
  const imgs = ['bg-1']

  const result = {}

  for (let i = 0; i < imgs.length; i++) {
    const item = imgs[i]
    result[
      item
    ] = require(`@/assets/images/page-body/dongshen-operation/${item}.png`)
  }

  return result
})

// 当前页面 跳转路由
const routes = [
  '/systemNantianJustice',
  '/systemHappyMeal',
  '/systemYunchongIntelligence'
]
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #f4f8fd;

  .image-box {
    .click-box {
      grid-template-rows: repeat(1, 250px);
      width: 1340px;
      top: 3190px;
    }
  }

  .bg-1 {
    width: 1920px;
    height: auto;
  }

  .floor-bg {
    margin-top: 60px;
  }
}
</style>
